@import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Space+Grotesk');

.countdown-number {
  font-family: 'Rampart One', cursive;
  font-size: 8rem;
  font-weight: 400;
  line-height: 190.47px;
  text-align: center;
  color: #8E9C9C;
}

.navbarStyle {
  font-family: 'Poppins', sans-serif;
  font-size: '16px';
  font-weight: '400';
  line-height: '22.56px';
  letter-spacing: '-0.02em';
  text-align: 'left';
}

.basecolor {
  background-color: #8E9C9C;
}
.countdown-label {
  font-family: 'Rampart One', cursive;
  text-align: center;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.text-6xl {
  font-size: 4rem; /* Adjust as necessary for your design */
}

.text-xl {
  font-size: 1.25rem; /* Adjust as necessary for your design */
}

.margin-top-9 {
  margin-top: 9rem ;
}

.banner-text {
  font-family: 'SpaceGrotesk', sans-serif !important;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: -0.055em;
}

.main-banner-fonts {
  width: 1290px;
  height: 71px;
  font-weight: 500;
  font-size: 56px;
  line-height: 71px;
}