@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button {
  border-radius: 9999px;
  /* This makes the edges fully rounded */
  padding: 0.75rem 2rem;
  /* Adjust padding to match the Figma design */
  background-color: #7E8794;
  /* Replace with the appropriate color code */
  color: white;
  font-size: 1.125rem;
  /* Adjust font size if needed */
}

.custom-border {
  @apply border;
  border-width: 0.86px;
  border-color: #CBCBCB;
}