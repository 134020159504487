.frame {
  height: 36px;
  overflow: hidden;
  position: relative;
  width: 1407px;
}

.frame .group {
  height: 36px;
  left: -47px;
  position: absolute;
  top: 0;
  width: 307px;
}

.frame .black {
  height: 34px;
  left: 5446px;
  position: absolute;
  top: 60px;
  width: 34px;
}

.frame .text-wrapper {
  color: #010101;
  font-family: "Inter-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 49px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .div {
  height: 36px;
  left: 293px;
  position: absolute;
  top: 0;
  width: 326px;
}

.frame .img {
  height: 28px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 24px;
}

.frame .text-wrapper-2 {
  color: #010101;
  font-family: "Inter-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 44px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .group-2 {
  height: 36px;
  left: 646px;
  position: absolute;
  top: 0;
  width: 404px;
}

.frame .black-2 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 37px;
}

.frame .text-wrapper-3 {
  color: #010101;
  font-family: "Inter-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 50px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.frame .group-3 {
  height: 36px;
  left: 1078px;
  position: absolute;
  top: 0;
  width: 348px;
}

.frame .black-3 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 34px;
}

.frame .group-4 {
  height: 36px;
  left: 1454px;
  position: absolute;
  top: 0;
  width: 331px;
}

.frame .black-4 {
  height: 34px;
  left: 3945px;
  position: absolute;
  top: 60px;
  width: 34px;
}

.frame .group-5 {
  height: 36px;
  left: 1814px;
  position: absolute;
  top: 0;
  width: 330px;
}

.frame .black-5 {
  height: 32px;
  left: 3585px;
  position: absolute;
  top: 60px;
  width: 32px;
}

.frame .text-wrapper-4 {
  color: #010101;
  font-family: "Inter-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 48px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}


.frame {
  height: 36px;
  overflow: hidden;
  position: relative;
  width: 100%;
  /* Use full width for responsiveness */
}

.animate-scroll {
  animation: scroll 200s linear infinite;
}

.animate-scroll:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(55%);
  }

  100% {
    transform: translateX(-69%);
  }
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}

.space-x-4>*+* {
  margin-left: 16px;
}