.main-link object svg {
  color: white;
}

.sub-link {
  display: none;
  color: #8E9C9C;
  background-color: #EEF0F0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: none;
}

.sub-link > div {
  border-top: 1px solid #DBDBDB;
}

.active-opt {
  border-radius: 4px;
  overflow: hidden;
}

.active-opt > .main-link {
  background-color: white;
  color: #8E9C9C !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.active-opt > .sub-link {
  display: block !important;
}
